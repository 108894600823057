import React, { useEffect, useState } from 'react'
import DEFAULT_URL from '../Utils/Variables/url';
import { Container, Row, Carousel, Col, Card, Button } from 'react-bootstrap';
import './style.css'
const CongresosRow = () => {
    const [contador] = useState(0);
    const [congresos, setCongresos] = useState([]);
    const [portadaCongresos, setPortadaCongresos] = useState('');
    const [index, setIndex] = useState(0);
    const [cambios, setCambios] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(DEFAULT_URL + '/api/read/evento/congresos/ebg-portal', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    /*
                    let tmp = [];
                    let actual = [];
                    data.rows.map((current, index) => {
                        if (((index + 1) % 3 === 0 && index > 0) || index === (data.rows.length - 1)) {
                            actual.push(current);
                            tmp.push(actual);
                            actual = [];
                        } else {
                            actual.push(current);
                        }
                        return true;
                    });
                    setCongresos(tmp);
                    */
                    setCongresos(data.rows);
                });
            fetch(DEFAULT_URL + '/api/congresos/info', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setPortadaCongresos(data.imagen);
                });
        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [cambios]);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setCambios(cambios + 1);
    };
    return (
        <Row style={{ padding: '2%' }}>
            <Col xs={12} md={12} lg={6} >
                <img loading='lazy' src={portadaCongresos} alt='portada-congresos' style={{ width: '100%', borderRadius: '2em' }} />
            </Col>
            <Col xs={12} md={12} lg={6}>
                <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    variant="dark"
                    controls={false}
                    indicators={false}
                    style={{ height: '100%' }}
                >
                    {
                        congresos.map((actual, indice) =>
                            <Carousel.Item key={indice}>
                                <Container fluid>
                                    <Row>
                                        <Col xs={6} md={6} lg={6} style={{ padding: '2%' }}>
                                            <img loading='lazy' src={actual.imagen} style={{ width: '100%', objectFit: 'contain' }} />
                                        </Col>
                                        <Col xs={6} md={6} lg={6} style={{  borderLeft: '5px solid #1e3d52', textAlign: 'left', padding: '2%' }}>
                                            <h1>{actual.Nombre}</h1>
                                            <p style={{ fontStyle: 'italic' }}>{actual.Descripcion}</p>
                                            <p><strong>Inicia: </strong>{actual.Fecha_Inicio + ', ' + actual.Hora}</p>
                                            <Button disabled={(actual.disponible == 0 || actual.diff < 0)}  variant="outline-dark" href={"https://ebg.edu.gt/inscripcion/" + actual.hash} target="_blank">Inscribirse</Button>

                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Item>
                        )
                    }
                </Carousel>
            </Col>
        </Row>
    )
}

export default CongresosRow