import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap';
import CongresosRow from './CongresosRow';
import AgendaRow from './AgendaRow';
const Home = () => {

    return (
        <Container fluid>
            <AgendaRow />
            <CongresosRow />
            <Row>
                <Carousel>
                    <Carousel.Item interval={2500}>
                        <img loading='lazy'
                            className="d-block w-100"
                            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/PORTAL-EBC/ebc-escuela-bancaria-centroamericana-01.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <img loading='lazy'
                            className="d-block w-100"
                            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/PORTAL-EBC/ebc-escuela-bancaria-centroamericana-02.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Row>
            <Row style={{ overflow: 'hidden' }}>
                <iframe loading='lazy' src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/EBC.mp4' title='video-ebc' style={{ width: '100%', height: '518px' }} />
            </Row>
        </Container>
    )
}

export default Home