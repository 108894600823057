import { Outlet } from "react-router-dom";
import { Container, Row, Navbar, Nav, Col, Alert, Card, ListGroup, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import DEFAULT_URL from "../Utils/Variables/url";
const Layout = () => {
    const [contador] = useState();
    const [cartera, setCartera] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetch(DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = [];
                    data.rows.map((actual, index) => {
                        if (index === 0) {
                            tmp.push({ tipo: actual.tipo, tipo_id: actual.tipo_id, eventos: [actual] });
                        } else {
                            const index_finder = (tipo) => tipo.tipo_id === actual.tipo_id;
                            if (tmp.findIndex(index_finder) >= 0) {
                                let temp = tmp[tmp.findIndex(index_finder)];
                                temp.eventos.push(actual);
                                tmp[tmp.findIndex(index_finder)] = temp;
                            } else {
                                tmp.push({ tipo: actual.tipo, tipo_id: actual.tipo_id, eventos: [actual] });
                            }
                            if (index === (data.rows.length - 1)) {
                                setCartera(tmp);
                            }
                        }
                        return true;
                    });
                });
        }
        fetchData();
    }, [contador]);

    return (<main className="App">
        <Container fluid className='layout'>
            <Row>
                <Navbar collapseOnSelect expand="xxl" bg="light" variant="light">
                    <Container fluid>
                        <img src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png" style={{ width: '8%' }} alt='abg-logos' />
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto" style={{ alignItems: 'center' }}>
                                <Nav.Item>
                                    ESCUELA BANCARIA CENTROAMERICANA
                                </Nav.Item>
                                <Nav.Link><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;+502 2382-7200</Nav.Link>
                            </Nav>
                            <Nav justify >
                                <Nav.Link href="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                    <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Inicio</Nav.Link>
                                <Nav.Link href="/info"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-lg" viewBox="0 0 16 16">
                                    <path d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                                </svg>
                                    &nbsp;&nbsp;&nbsp;&nbsp;Mas información</Nav.Link>
                                <Nav.Link onClick={() => {
                                    window.scrollTo(0, document.querySelector(".layout").scrollHeight);

                                }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Contacto</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>
            <img onClick={() => { window.open("https://wa.me/+50223827200", "_blank"); }} src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/whatsapp.png" style={{ position: 'fixed', right: '10px', bottom: '15px', width: '70px', zIndex: 2, cursor: 'pointer' }} alt="whatsapp-icon" />
            <Row>
                <Outlet />
            </Row>
            <Row style={{ backgroundColor: '#E6E6E6', padding: '2%' }}>
                <Col xs={12} md={12} lg={3} style={{ marginTop: '3px' }}>
                    <Card style={{ width: '100%', padding: '5%' }}>
                        <Card.Img variant="top" src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/logo+EBC.png" alt='logos' />
                        <Card.Body>
                            <Card.Title>
                                <strong style={{ textAlign: 'center' }}>La Escuela Bancaria Centroamericana EBC</strong><br />
                            </Card.Title>
                            <Card.Text style={{ textAlign: 'justify' }}>
                                La Entidad Educativa de Formación, Capacitación y Actualización, marca de la Asociación Bancaria de Guatemala ABG; para el servicio y atención de las necesidades, requerimientos del Sector Bancario, Financiero y sus clientes de la región Centroamericana y del Caribe.                            </Card.Text>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                                <Button variant='outline-dark' style={{ width: '100%' }} href="https://www.facebook.com/profile.php?id=100064109768792" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                </svg>&nbsp;&nbsp;&nbsp;Facebook</Button>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button variant='outline-dark' style={{ width: '100%' }} href="https://www.linkedin.com/in/ebc-escuela-bancaria-centroamericana-958014201/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                </svg>&nbsp;&nbsp;&nbsp;LinkedIn</Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
                <Col xs={12} md={6} lg={3} style={{ marginTop: '3px' }}>
                    <h1>Actividades</h1>
                    {cartera.map((actual, index) => <Row key={index} style={{ padding: '5px' }}>
                        <Button variant='outline-secondary' href={'/eventos/' + actual.tipo} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mortarboard-fill" viewBox="0 0 16 16">
                            <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z" />
                            <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;{actual.tipo}</Button>
                    </Row>)}
                </Col>
                <Col xs={12} md={6} lg={3} style={{ marginTop: '3px' }}>
                    <h1>Contacto</h1>
                    <Row style={{ padding: '5px' }}>
                        <p><strong><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Teléfono <br /></strong>(502) 2382-7200</p>
                    </Row>
                    <Row style={{ padding: '5px' }}>
                        <p><strong><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Email <br /></strong>escuelabancariaca@ebg.edu.gt</p>
                    </Row>
                    <Row style={{ padding: '5px' }}>
                        <p><strong><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-browser-chrome" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;WebSite <br /></strong>https://www.ebc.education</p>
                    </Row>
                    <Row style={{ padding: '5px' }}>
                        <p><strong><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Whatsapp <br /></strong>(502) 5748-0970</p>
                    </Row>
                </Col>
            </Row>
            <Row style={{}}>
                <Alert variant="light">
                    Copyright © 2021 | EBC - Escuela Bancaria Centroamericana
                </Alert>
            </Row>
        </Container>
    </main >);
}

export default Layout;