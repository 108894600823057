import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Unauthorized from './Components/Unauthorized/Unauthorized';
import Home from './Components/home/home';
import Layout from './Components/layout/Layout';
import Info from './Components/info/info';
import Eventos from './Components/eventos/eventos';
function App() {
  return (
    <Router>
      <Routes>
        <Route
          estrict path="/"
          element={<Navigate to="/home" replace />}
        />
        <Route path='/' element={<Layout />}>
          <Route path='home' element={<Home />} />
          <Route path='info' element={<Info />} />
          <Route path='eventos/:tipo' element={<Eventos />} />
        </Route>
        <Route path='*' element={<Unauthorized />} />
      </Routes>
    </Router>
  );
}

export default App;
