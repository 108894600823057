import React from 'react'
import { Container, Row} from 'react-bootstrap';

const Info = () => {
    return (
        <Container fluid>
            <Row>
                <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/CERTIFICACIONES-EBG-V.jpg' alt='certificaciones' />
            </Row>
            <Row>
                <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/DIPLOMADOS-EBG-V.jpg' alt='diplomados' />
            </Row>
            <Row>
                <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/MARTES-DE-PREVENCION-2023.jpg' alt='martes-prevencion' />
            </Row>
            <Row>
                <iframe src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/EBG+Programacion+PLDYFT+2023.pdf' style={{ height: '900px' }} title='pldyft' />
            </Row>
        </Container>
    )
}

export default Info