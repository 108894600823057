import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import DEFAULT_URL from '../Utils/Variables/url';
import './style.css'
const Eventos = () => {
    const { tipo } = useParams();
    const [contador] = useState(0);
    const [cartera, setCartera] = useState([]);
    const [imgAgenda, setImgAgenda] = useState('');
    useEffect(() => {
        async function fetchData() {
            fetch(DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = [];
                    data.rows.map((actual, index) => {
                        if (index === 0) {
                            tmp.push({ tipo: actual.tipo, tipo_id: actual.tipo_id, eventos: [actual] });
                        } else {
                            const index_finder = (tipo) => tipo.tipo_id === actual.tipo_id;
                            if (tmp.findIndex(index_finder) >= 0) {
                                let temp = tmp[tmp.findIndex(index_finder)];
                                temp.eventos.push(actual);
                                tmp[tmp.findIndex(index_finder)] = temp;
                            } else {
                                tmp.push({ tipo: actual.tipo, tipo_id: actual.tipo_id, eventos: [actual] });
                            }
                            if (index === (data.rows.length - 1)) {
                                setCartera(tmp);
                                console.log(tmp);
                            }
                        }
                        return true;
                    });
                });
            fetch(DEFAULT_URL + '/api/agenda/info', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setImgAgenda(data.imagen);
                });
        }
        fetchData();
    }, [contador])

    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={12} lg={6} style={{ padding: '5%' }}>
                    <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logotipos+Finales+ABG+2022-01.png' alt='logos' style={{ width: '100%' }} />
                </Col>
                <Col xs={12} md={12} lg={6} style={{ padding: '5%' }}>
                    <img src={imgAgenda} alt='logos' style={{ width: '100%' }} />
                    <h1>{tipo}</h1>
                </Col>
            </Row>
            {
                cartera && cartera.map((tipo_evt, indice) =>
                    <Row key={indice}>
                        {
                            tipo_evt.tipo === tipo && <Container fluid>
                                {
                                    tipo_evt.eventos.map((evento, index) => <Row className='row-agenda'>
                                        <Col xs={12} md={12} lg={6} className='col-agenda'>
                                            <Card style={{ height: '100%' }}>
                                                <Card.Img loading="lazy" variant="top" src={evento.flier} />
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={12} lg={6} className='col-agenda'>
                                            <Card style={{ height: '100%', color: 'black' }}>
                                                <Card.Img variant="top" src={evento.img} />
                                                <Card.Body className='card-agenda'>
                                                    <Card.Title>{evento.Nombre}</Card.Title>
                                                    <Card.Text>{evento.Descripcion}</Card.Text>
                                                    <Button variant="outline-dark" href={"https://ebg.edu.gt/inscripcion/" + evento.hash} target="_blank">Inscribirme en línea</Button>
                                                </Card.Body>
                                                <Card.Footer style={{ color: 'black' }}>Inicia el {evento.Fecha_Inicio}</Card.Footer>
                                            </Card>
                                        </Col>
                                    </Row>)
                                }
                            </Container>
                        }
                    </Row>
                )
            }
        </Container>
    )
}

export default Eventos